/* eslint-disable no-nested-ternary */
define([
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/_base/kernel',
  'dojo/when',
  'dojo/Stateful',
  'dojo/Deferred',
  'dojo/ready',
  'dojo/dom-class',
  'scramble/SessionManager',
  'scramble/CookieSettingStore',
  'dojo/topic',
  'dojox/app/module/lifecycle',
  'dojo/i18n!scramble/nls/common',
  'scramble-com/modules/sentry',
  'scramble-com/modules/user',
  'scramble-com/modules/abstract-catalog',
  'scramble-com/modules/object-query-matcher',
  'scramble-com/modules/user-session-tracer',
  'scramble-com/store',
  'scramble-com/actions',
], (
  declare,
  lang,
  array,
  dojo,
  when,
  Stateful,
  Deferred,
  ready,
  domClass,
  SessionManager,
  CookieSettingStore,
  topic,
  lifecycle,
  commonNls,
  sentry,
  { currentDashboardBrand },
  {
    brandKey: brandKeyFromCatalog,
    brandsIncludeBrand,
  },
  { conditionMatcherFor },
  { default: userSessionTracer },
  { getInstance: getStoreInstance },
  actions,
) => {
  const store = getStoreInstance();

  return new (declare([Stateful], {

    fields: {
      readyState: 'starting',
      user: null,
      sessionToken: null,
      document: null,
      customCatalog: null,
      brand: null,
      stagedCsvData: null,
      newDocumentMode: null,
      overrideCustomer: null,
      customer: undefined,
      isSessionValid: true,
    },

    userSettings: new CookieSettingStore({
      priceType: 'wholesale',
      locale: dojo.locale,
      defaultView: 'view',
      defaultOrderMode: '',
      showAddToCatalogButtonsInProductRow: false,
      defaultPaperSize: 'northAmerican',
    }, 'userSettings'),

    constructor() {
      this.inherited(arguments);

      if (this.fields) {
        this.set(this.fields);
      }

      const token = SessionManager.getToken();
      this.set('sessionToken', token);
      this.whenReady().then(lang.hitch(this, 'renewSessionToken', token));

      topic.subscribe('/app/status', lang.hitch(this, function (evt) {
        if (evt == (new lifecycle()).lifecycle.STARTED) {
          ready(lang.hitch(this, function () {
            this.set('readyState', 'ready');
          }));
        }
      }));

      topic.subscribe('scramble/stageCsvData', lang.hitch(this, function (tabularData) {
        this.set('stagedCsvData', tabularData);
      }));

      this.requestVersionInfo()
        .then(version => (this.set('version', version)))
        .catch(error => {
          this.set('version', {
            scramble_rev: 'N/A',
            scramble_ref: 'N/A',
            scramble_com_rev: 'N/A',
            scramble_com_ref: 'N/A',
          });
          console.error('error initializing version info', error);
        });

      Promise.all([
        this.whenDefined('version'),
        this.whenDefined('appConfig'),
      ]).then(() => {
        this.initializeSentry();
        userSessionTracer().setClient(window.shortClientKey);
      });
    },

    usefulEnvironment() {
      return ([
        ['localhost', 'development'],
        ['://cors.', 'development'],
        ['staging.elasticsuite.com', 'staging'],
        ['beta.elasticsuite.com', 'beta'],
      ].find(([part]) => window.location.href.match(part)) || [null, 'production'])[1];
    },

    initializeSentry() {
      const environment = this.usefulEnvironment();

      if (environment.match(/development$/)) return;

      const releaseVersion = this.version.scramble_rev.match(/^release-/)
        ? this.version.scramble_rev
        : this.version.scramble_ref;

      const release = `scramble4@${releaseVersion}`;

      sentry.initialize({
        environment: `${window.shortClientKey}_${environment}`,
        release,
        maxBreadcrumbs: 1, // 30,
      });
    },

    requestVersionInfo() {
      return fetch(`/version.json?t=${new Date().getTime()}`)
        .then(res => res.json());
    },

    renewSessionToken(token) {
      token = typeof (token) === 'undefined' ? this.get('sessionToken') : token;
      if (token) SessionManager.write(token, SessionManager.getRememberSetting());
    },

    _appConfigSetter(appConfig) {
      appConfig.images = {
        logo: 'client/images/logo.png',
        mainBackground: 'client/images/mainBackground.jpg',
        ...appConfig.images,
      };

      this.appConfig = appConfig;

      const { environments } = appConfig;
      if (!environments) {
        return;
      }

      for (const key in environments) {
        if (environments.hasOwnProperty(key)) {
          const environment = environments[key];
          if (window.location.host.match(environment.server)) {
            this.set('env', key);
            this.set(environment);
          }
        }
      }

      if (!this.userSettings._isWritten('priceType') && appConfig.flags.initialPriceType) {
        this.userSettings.set('priceType', appConfig.flags.initialPriceType);
      }

      // Basically clones the object and deletes the __parent reference
      // so that it isn't cyclical
      const flags = lang.mixin({}, appConfig.flags, { __parent: null });
      delete flags.__parent;
      this.flags = flags;
    },

    _apiTargetGetter() {
      return '/api/';
    },

    _apiPathGetter() {
      return this.getFlag('apiPath', '/api/');
    },

    _flagsGetter() {
      if (this.flags) {
        return this.flags;
      } if (this.appConfig && this.appConfig.flags) {
        return this.appConfig.flags;
      }
      return {};
    },

    getFlag(path, defaultValue) {
      const value = lang.getObject(path, false, this.get('flags'));
      if (value === null || value === undefined) return defaultValue;
      return value;
    },

    getNlsFlag(path, nlsObject = commonNls, defaultValue) {
      const flag = this.getFlag(`i18n.${path}`, defaultValue || '');
      // nls:key|alternative
      const [keyPart, alternative] = flag.split('|');
      const str = keyPart.match(/^nls:/) ? nlsObject[keyPart.split(':')[1]] : keyPart;
      return str || alternative;
    },

    getClientName() {
      return this.getFlag('overrideApplicationName')
        ? this.getFlag('overrideApplicationName')
        : this.get('appConfig').name;
    },

    isLoggedIn() {
      return this.get('sessionToken');
    },

    whenReady() {
      return this.whenSetTo('readyState', 'ready');
    },

    getLandingPage() {
      if (!this.user) return 'dashboard';
      const isRep = this.user.type === 'rep';
      return this.getFlag('directoryMode')
        ? 'explore'
        : isRep
          ? this.getFlag('defaultLandingPageReps')
          : this.getFlag('defaultLandingPageRetailer')
    },

    sessionValidity(validity) {
      this.set('isSessionValid', validity);
    },

    /**
     * TODO: Add these flag(s) into config: (n.b. Not a hard requirement, just a net positive that will help in many areas - Deployment, Testing, etc.)
     * 1. targetEnv is set at scramble build time (Options should be binary for this level of detail: development, production)
     * 2. currentEnv is dynamically set, probably should be picked up from the config.json hosts definition.
     */
    isProduction: function isProduction() {
      if (/\.test\.|\.qa\.|\.beta\.|\.staging\.|\.develop\.|localhost\:/i.test(location.host)) {
        return false;
      }
      if (this.getFlag('targetEnv') === 'production') { return true; }
      if (this.getFlag('currentEnv') === 'production') { return true; }
      return true; // IMPORTANT: Is this a safe default??
    },

    _savingHandle: null,
    _documentSetter(value) {
      this.document = value;

      if (!value) {
        this.set('brand', null);
        return false;
      }

      const catalog = this.document.get('catalog');
      const catalogBrandKey = catalog ? brandKeyFromCatalog(catalog) : null;

      let brandKey = currentDashboardBrand(this.user) || catalogBrandKey;
      if (
        !this.appConfig.flags.catalogBrands
        || !brandsIncludeBrand(this.appConfig.flags.catalogBrands, brandKey)
      ) {
        brandKey = null;
      }

      this.set('brand', brandKey || this.selectedBrand);

      this.document.watch('saving', (n, o, saving) => {
        if (!saving && this._savingHandle) {
          clearTimeout(this._savingHandle);
          this._savingHandle = null;
        } else {
          this._savingHandle = setTimeout(() => {
            this.document.set({
              saving: false,
              dirty: true,
            });

            this._savingHandle = null;
          }, 5 * 60 * 1000);
        }
      });
    },

    _shippingMethodsSettingGetter() {
      return this.getFlag('shipViaOptions', [
        { label: 'Default', value: 'S' },
        { label: 'Overnight', value: 'O' },
        { label: '2nd day', value: '2' },
        { label: '3-day', value: '3' },
        { label: 'Ground', value: 'G' },
      ]);
    },

    _customCatalogSetter(value) {
      if (!value) {
        this.set('brand', null);
        return false;
      }

      this.customCatalog = value;
      const catalog = this.customCatalog.get('catalog');

      const catalogBrandKey = catalog ? brandKeyFromCatalog(catalog) : null;

      if (!this.user) return;
      let brandKey = currentDashboardBrand(this.user) || catalogBrandKey;
      if (
        !this.appConfig.flags.catalogBrands
        || !brandsIncludeBrand(this.appConfig.flags.catalogBrands, brandKey)
      ) {
        brandKey = null;
      }

      this.set('brand', brandKey || this.selectedBrand);
    },

    _brandSetter(brand) {
      if (!this.appConfig) {
        return;
      }

      const brands = this.appConfig.flags.catalogBrands;
      if (!brands) {
        return;
      }

      this.brand = brand;

      // 99% sure this is dead code
      // like does the less build even pull in the client's config.less?
      const brandClasses = array.map(brands, 'return "brand-" + item;');
      domClass.remove(document.body, brandClasses);

      if (brand) {
        domClass.add(document.body, `brand-${brand}`);
      }
    },

    _userSetter(user) {
      if (user) {
        if (user.type == 'dealer') {
          this.userSettings.set('defaultView', this.getFlag('defaultViewForDealerUser'));
        } else {
          this.userSettings.set('defaultView', this.getFlag('defaultViewForRepUser'));
        }
      }
      this.user = user;

      sentry.setUser(user);
      userSessionTracer().setUser(user);

      if (user) {
        store.dispatch(actions.logIn(user.username));
      } else {
        store.dispatch(actions.logOut());
      }
    },

    /* Weird IAB price groups, only exist in IAB context */

    _wholesalePriceGroupsGetter() {
      return this.appConfig.wholesalePriceGroups;
    },

    _retailPriceGroupsGetter() {
      return this.appConfig.retailPriceGroups;
    },

    /* End IAB weirdness */

    onUserChange(callback) {
      when(this.whenDefined('user'), lang.hitch(this, function (user) {
        callback(user);
        this.watch('user', (n, o, user) => {
          callback(user);
        });
      }));
    },

    whenDefined(key) {
      const dfd = new Deferred();

      let val;
      if (val = this.get(key)) {
        dfd.resolve(val);
      } else {
        var handle = this.watch(key, lang.hitch(this, function () {
          let val;
          if (val = this.get(key)) {
            dfd.resolve(val);
            handle.unwatch();
          }
        }));
      }

      return dfd;
    },

    whenSetTo(key, value) {
      const dfd = new Deferred();

      let val;
      if ((val = this.get(key)) === value) {
        dfd.resolve(val);
      } else {
        var handle = this.watch(key, lang.hitch(this, function () {
          let val;
          if ((val = this.get(key)) === value) {
            dfd.resolve(val);
            handle.unwatch();
          }
        }));
      }

      return dfd;
    },

    getEmbellishmentStrategies() {
      // iab doesn't support embellishment
      if (this.getFlag('iab')) return [];

      const strategies = this.getFlag('embellishmentStrategies');

      const legacyConfig = this._getArtifiConfig();

      if (strategies.length) return strategies;

      // an up-to-date config was ruled out
      if (legacyConfig) return [legacyConfig];

      return [];
    },

    _getArtifiConfig() {
      return this.getFlag('artifi', {})[this.isProduction() ? 'production' : 'staging'];
    },

    usesEmbellishmentUpcharges() {
      // true for Artifi -B2B, -B2C and CYRP embellishments
      // may include more strategies in the future
      return this.getEmbellishmentStrategies()
        .some(({ websiteId, provider }) => websiteId || provider === 'CYRP');
    },

    usesCopiableEmbellishments() {
      // currently there are three providers
      // ArtifiB2B is the only one that supports
      // copying embellishments
      return this.getEmbellishmentStrategies()
        .some(({ provider }) => provider === 'ArtifiB2B');
    },

    _arrayWhere(list) {
      const doc = this.get('document');
      const customer = doc && doc.get('customer');
      const catalog = doc && doc.get('catalog');
      const user = this.get('user');

      if (!customer || !user || !catalog || !Array.isArray(list)) return [];

      return list
        .filter(conditionMatcherFor(customer, 'customer'))
        .filter(conditionMatcherFor(catalog, 'catalog'))
        .filter(conditionMatcherFor(user, 'user'));
    },

    activeIntegrationFields() {
      return this._arrayWhere(this.getFlag('integrationFields'));
    },

    activePageCheckoutFieldGroups() {
      return this._arrayWhere(this.getFlag('checkoutFields.page'));
    },

    activeOrderCheckoutFieldGroups() {
      return this._arrayWhere(this.getFlag('checkoutFields.order'));
    },

    pageIntegrationFields() {
      return this.activeIntegrationFields()
        .filter(({ location }) => /^\$page(\.)?$/.test(location));
    },

  }))();
});
