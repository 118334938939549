export default {
'scramble/views/Splash': () => import('scramble/views/Splash'),
'scramble/views/splash/Login': () => import('scramble/views/splash/Login'),
'scramble/views/Blank': () => import('scramble/views/Blank'),
'scramble/views/splash/ResetPassword': () => import('scramble/views/splash/ResetPassword'),
'scramble/views/splash/Register': () => import('scramble/views/splash/Register'),
'scramble/views/splash/DealerRequest': () => import('scramble/views/splash/DealerRequest'),
'scramble/views/splash/Unsubscribe': () => import('scramble/views/splash/Unsubscribe'),
'scramble/views/Dashboard': () => import('scramble/views/Dashboard'),
'scramble/views/dashboard/Home': () => import('scramble/views/dashboard/Home'),
'scramble/views/dashboard/Campaigns': () => import('scramble/views/dashboard/Campaigns'),
'scramble/views/dashboard/NewOrder': () => import('scramble/views/dashboard/NewOrder'),
'scramble/views/dashboard/NewCustomCatalog': () => import('scramble/views/dashboard/NewCustomCatalog'),
'scramble/views/dashboard/OrderStatus': () => import('scramble/views/dashboard/OrderStatus'),
'scramble/views/Blank': () => import('scramble/views/Blank'),
'scramble/views/dashboard/orderStatus/Chart': () => import('scramble/views/dashboard/orderStatus/Chart'),
'scramble/views/dashboard/ExternalOrderHistory': () => import('scramble/views/dashboard/ExternalOrderHistory'),
'scramble/views/dashboard/Documents': () => import('scramble/views/dashboard/Documents'),
'scramble/views/dashboard/OpenFromDesktop': () => import('scramble/views/dashboard/OpenFromDesktop'),
'scramble/views/dashboard/OpenFromDealer': () => import('scramble/views/dashboard/OpenFromDealer'),
'scramble/views/dashboard/CustomCatalogs': () => import('scramble/views/dashboard/CustomCatalogs'),
'scramble/views/dashboard/Catalogs': () => import('scramble/views/dashboard/Catalogs'),
'scramble/views/dashboard/PrintJobs': () => import('scramble/views/dashboard/PrintJobs'),
'scramble/views/dashboard/AssetManager': () => import('scramble/views/dashboard/AssetManager'),
'scramble/views/dashboard/UserManager': () => import('scramble/views/dashboard/UserManager'),
'scramble/views/dashboard/AccountSettings': () => import('scramble/views/dashboard/AccountSettings'),
'scramble/views/dashboard/Links': () => import('scramble/views/dashboard/Links'),
'scramble/views/dashboard/SearchView': () => import('scramble/views/dashboard/SearchView'),
'scramble/views/dashboard/ReportOrders': () => import('scramble/views/dashboard/ReportOrders'),
'scramble/views/Explore': () => import('scramble/views/Explore'),
'scramble/views/explore/Explore': () => import('scramble/views/explore/Explore'),
'scramble/views/dashboard/Home': () => import('scramble/views/dashboard/Home'),
'scramble/views/explore/Manage': () => import('scramble/views/explore/Manage'),
'scramble/views/home/Brands': () => import('scramble/views/home/Brands'),
'scramble/views/home/BrandDetails': () => import('scramble/views/home/BrandDetails'),
'scramble/views/home/CatalogList': () => import('scramble/views/home/CatalogList'),
'scramble/views/home/CollectionList': () => import('scramble/views/home/CollectionList'),
'scramble/views/home/Search': () => import('scramble/views/home/Search'),
'scramble/views/dashboard/Campaigns': () => import('scramble/views/dashboard/Campaigns'),
'scramble/views/dashboard/NewOrder': () => import('scramble/views/dashboard/NewOrder'),
'scramble/views/dashboard/NewCustomCatalog': () => import('scramble/views/dashboard/NewCustomCatalog'),
'scramble/views/dashboard/OrderStatus': () => import('scramble/views/dashboard/OrderStatus'),
'scramble/views/Blank': () => import('scramble/views/Blank'),
'scramble/views/dashboard/orderStatus/Chart': () => import('scramble/views/dashboard/orderStatus/Chart'),
'scramble/views/dashboard/ExternalOrderHistory': () => import('scramble/views/dashboard/ExternalOrderHistory'),
'scramble/views/dashboard/Documents': () => import('scramble/views/dashboard/Documents'),
'scramble/views/dashboard/OpenFromDesktop': () => import('scramble/views/dashboard/OpenFromDesktop'),
'scramble/views/dashboard/OpenFromDealer': () => import('scramble/views/dashboard/OpenFromDealer'),
'scramble/views/dashboard/CustomCatalogs': () => import('scramble/views/dashboard/CustomCatalogs'),
'scramble/views/dashboard/Catalogs': () => import('scramble/views/dashboard/Catalogs'),
'scramble/views/dashboard/PrintJobs': () => import('scramble/views/dashboard/PrintJobs'),
'scramble/views/dashboard/AssetManager': () => import('scramble/views/dashboard/AssetManager'),
'scramble/views/dashboard/UserManager': () => import('scramble/views/dashboard/UserManager'),
'scramble/views/dashboard/AccountSettings': () => import('scramble/views/dashboard/AccountSettings'),
'scramble/views/dashboard/Links': () => import('scramble/views/dashboard/Links'),
'scramble/views/dashboard/ReportOrders': () => import('scramble/views/dashboard/ReportOrders'),
'scramble/views/dashboard/ReportInventory': () => import('scramble/views/dashboard/ReportInventory'),
'scramble/views/dashboard/Metrics': () => import('scramble/views/dashboard/Metrics'),
'scramble/views/Builder': () => import('scramble/views/Builder'),
'scramble/views/builder/Browse': () => import('scramble/views/builder/Browse'),
'scramble/views/builder/Order': () => import('scramble/views/builder/Order'),
'scramble/views/builder/Grid': () => import('scramble/views/builder/Grid'),
'scramble/views/builder/Sizing': () => import('scramble/views/builder/Sizing'),
'scramble/views/builder/SalesPrograms': () => import('scramble/views/builder/SalesPrograms'),
'scramble/views/builder/Cart': () => import('scramble/views/builder/Cart'),
'scramble/views/builder/Overview': () => import('scramble/views/builder/Overview'),
'scramble/views/builder/Detail': () => import('scramble/views/builder/Detail'),
'scramble/views/builder/Summary': () => import('scramble/views/builder/Summary'),
'scramble/views/builder/SalesPrograms': () => import('scramble/views/builder/SalesPrograms'),
'scramble/views/builder/Purchase': () => import('scramble/views/builder/Purchase'),
'scramble/views/builder/Sizing': () => import('scramble/views/builder/Sizing'),
'scramble/views/builder/Whiteboard': () => import('scramble/views/builder/Whiteboard'),
'scramble/views/customCatalog/Builder': () => import('scramble/views/customCatalog/Builder'),
'scramble/views/customCatalog/builder/Editor': () => import('scramble/views/customCatalog/builder/Editor')
};